@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-primary: 70 78 184;
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

#__next {
  width: 100%;
  min-height: 100vh;
}

.clickable {
  cursor: pointer;
  color: rgb(29 78 216);
}

.clickable:hover {
  text-decoration: underline;
  color: rgb(59 130 246)
}
