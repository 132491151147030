.p-inputtext {
  border-radius: 0.25rem;
  border: none;
  background-color: rgb(243, 242, 241);
}

.p-datepicker table td.p-datepicker-today > span {
  background: rgb(92, 94, 191)
}

.p-component, .p-link {
  font-family: "Segoe UI", system-ui, "Apple Color Emoji", "Segoe UI Emoji", sans-serif;
}
